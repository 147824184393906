import storageService from "@/services/storage-service";

export default {
  data() {
    return {
      currentUser: null,
    };
  },

  created() {
    this.currentUser = storageService.getItem("user");
  },

  methods: {
    /**
     * Check if user has a specific privilege
     * @param {String} privilegeName - Name of the privilege to check
     * @param {String} appName - Name of the app (defaults to 'Credit Scoring Model')
     * @returns {Boolean} - Whether user has the privilege
     */
    hasPrivilege(privilegeName, appName = "Credit Scoring Model") {
      if (!this.currentUser || !this.currentUser.privileges) {
        return false;
      }

      const privilege = this.currentUser.privileges.find(
        (p) =>
          p.privilege_app === appName &&
          p.privilege_name === privilegeName &&
          p.is_activated === true,
      );

      return !!privilege;
    },

    /**
     * Show error message for unauthorized access
     * @param {String} privilegeName - Name of the privilege required
     */
    showUnauthorizedError(privilegeName) {
      this.$store.dispatch("showSnackbar", {
        text: `Access denied: You don't have the required "${privilegeName}" privilege`,
        color: "error",
      });
    },
  },
};
