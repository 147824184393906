//CreditScoringReport.vue
<template>
  <v-container fluid>
    <!-- Features Menu -->
    <CreditScoringFeaturesMenu />
    <!-- Global Loader -->
    <data-loading :visible="dataLoading"></data-loading>

    <v-card flat>
      <v-card-title class="py-2 px-4" style="background-color: #f5f5f5;">
        <div class="headline font-weight-regular">Credit Scoring Mass Report</div>
        <v-spacer></v-spacer>
        
        <!-- File Selection Dialog Button -->
        <v-btn
          color="primary"
          @click="openFileSelector"
          class="ml-4"
          v-if="hasPrivilege('Generate Reports')"
        >
          <v-icon left>attach_file</v-icon>
          {{ selectedUpload ? 'Change File' : 'Select Upload File' }}
        </v-btn>
        
        <!-- Selected File Display -->
        <span v-if="selectedUpload" class="d-flex align-center ml-2">
          <v-chip
            outlined
            class="mr-1"
          >
            {{ getSelectedUploadName() }}
          </v-chip>
          <v-btn
            icon
            small
            @click="clearSelectedFile()"
            title="Clear selection"
            v-if="hasPrivilege('Generate Reports')"
          >
            <v-icon small>clear</v-icon>
          </v-btn>
        </span>
        
        <v-btn
          flat
          :loading="loading"
          :disabled="!selectedUpload"
          class="ml-4"
          @click="handleUploadSelect"
          v-if="hasPrivilege('Generate Reports')"
        >
          Generate Report
        </v-btn>
        <v-btn
          flat
          :loading="exporting"
          :disabled="!reportId"
          class="ml-2"
          @click="exportToExcel"
          v-if="hasPrivilege('Generate Reports')"
        >
          <v-icon left small>save_alt</v-icon>
          Export
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <!-- Add view-only alert -->
      <v-alert
        v-if="!hasPrivilege('Generate Reports')"
        type="warning"
        dense
        outlined
        class="ma-4"
      >
        You have view-only access. The "Generate Reports" privilege is required to generate new reports.
      </v-alert>

      <v-card-text v-if="summary" class="pa-4">
        <!-- Key Metrics -->
        <div class="subheading font-weight-medium mb-3">Key Portfolio Metrics</div>
        <v-layout row wrap>
          <v-flex xs12 sm6 md3>
            <v-card class="ma-2" flat>
              <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                <div class="caption grey--text mb-1">TOTAL LOANS</div>
                <div class="title font-weight-medium">{{ summary.totalLoans }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <v-card class="ma-2" flat>
              <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                <div class="caption grey--text mb-1">AVERAGE SCORE</div>
                <div class="title font-weight-medium">{{ summary.averageScore.toFixed(2) }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <v-card class="ma-2" flat>
              <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                <div class="caption grey--text mb-1">HIGHEST SCORE</div>
                <div class="title font-weight-medium">{{ summary.highestScore.toFixed(2) }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <v-card class="ma-2" flat>
              <v-card-text class="pa-3" style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;">
                <div class="caption grey--text mb-1">LOWEST SCORE</div>
                <div class="title font-weight-medium">{{ summary.lowestScore.toFixed(2) }}</div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- Distribution Section -->
        <div class="mt-4 mb-3">
          <div class="subheading font-weight-medium">Score Distribution</div>
        </div>

        <v-card flat style="border: 1px solid rgba(0,0,0,0.12); border-radius: 4px;" class="mb-4">
          <v-card-text class="pa-4">
            <v-layout row wrap>
              <v-flex v-for="(value, key) in summary.scoreDistribution" :key="key" xs12 sm6 md3>
                <div class="text-xs-center">
                  <div class="title font-weight-medium grey--text text--darken-2">{{ value }}</div>
                  <div class="body-2">{{ key }}</div>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <!-- Filters -->
        <div class="subheading font-weight-medium mt-4 mb-2">Data Filters</div>
        <v-layout row wrap>
          <v-flex xs12 sm6 md4>
            <v-text-field
              v-model="search"
              label="Search"
              prepend-icon="search"
              clearable
              class="mr-3"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-select
              v-model="scoreFilter"
              :items="scoreRanges"
              label="Score Range"
              clearable
            ></v-select>
          </v-flex>
        </v-layout>
      </v-card-text>

      <!-- Data Table -->
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredLoans"
          :loading="loading"
          :search="search"
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.customerName }}</td>
            <td>{{ props.item.loanReferenceNumber }}</td>
            <td class="text-xs-right">{{ asMoney(props.item.originalLoanAmount) }}</td>
            <td class="text-xs-right">{{ asMoney(props.item.currentLoanBalance) }}</td>
            <td>{{ asMoney(props.item.ecl) }}</td>
            <td>{{ asMoney(props.item.interestAccrued) }}</td>
            <td>{{ (props.item.stage) }}</td>
            <td>{{ formatDate(props.item.loanOriginationDate) }}</td>
            <td>{{ formatDate(props.item.loanMaturityDate) }}</td>
            <td class="text-xs-center">
              <v-chip small outline>
                {{ props.item.creditScore.toFixed(2) }}
              </v-chip>
            </td>
            <td class="text-xs-center">
              <v-btn small flat @click="showDetails(props.item)">
                Details
              </v-btn>
            </td>
          </template>
        </v-data-table>

        <!-- File Selection Dialog -->
        <v-dialog v-model="fileDialogOpen" max-width="800px" scrollable>
          <v-card>
            <v-card-title class="py-3 px-4" style="background-color: #f5f5f5;">
              <span class="subtitle-1 font-weight-medium">Select Upload File</span>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="fileSearch"
                append-icon="search"
                label="Search files"
                single-line
                hide-details
                clearable
                class="ml-2"
                style="max-width: 300px"
              ></v-text-field>
            </v-card-title>
            
            <v-divider></v-divider>

            <v-card-text class="pa-0">
              <!-- Year Filter Bar -->
              <v-toolbar flat dense class="elevation-0">
                <v-select
                  v-model="filterYear"
                  :items="availableYears"
                  label="Year"
                  clearable
                  hide-details
                  class="mx-2"
                  style="max-width: 100px"
                ></v-select>
                
                <v-spacer></v-spacer>
                
                <div class="body-2 mr-2">
                  {{ filteredUploads.length }} files
                </div>
              </v-toolbar>
              
              <v-divider></v-divider>
              
              <!-- Files List -->
              <v-data-table
                ref="fileTable"
                :headers="fileHeaders"
                :items="filteredUploads"
                :loading="loading"
                :search="fileSearch"
                :rows-per-page-items="[10, 25, 50, 100]"
                class="elevation-0"
              >
                <template slot="items" slot-scope="props">
                  <tr 
                    @click="selectFileFromList(props.item)" 
                    :class="{'selected-file': tempSelectedUpload === props.item._id}"
                    style="cursor: pointer"
                  >
                    <td>{{ props.item.file_name }}</td>
                    <td>{{ props.item.month }}/{{ props.item.year }}</td>
                    <td>{{ formatDate(props.item.created_at) }}</td>
                  </tr>
                </template>
                <template slot="no-data">
                  <div class="text-xs-center pa-4">
                    <v-icon large class="mb-2 grey--text">cloud_upload</v-icon>
                    <div v-if="loading">Loading files...</div>
                    <div v-else-if="fileSearch || filterYear">No matching files found. Try changing your search filters.</div>
                    <div v-else>No files available</div>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
            
            <v-divider></v-divider>
            
            <v-card-actions style="background-color: #f5f5f5;">
              <v-btn color="primary" flat :disabled="!tempSelectedUpload" @click="confirmFileSelection">
                Select File
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn flat @click="fileDialogOpen = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Details Dialog -->
        <v-dialog v-model="dialog" max-width="800px">
          <v-card v-if="selectedLoan">
            <v-card-title class="py-3 px-4" style="background-color: #f5f5f5;">
              <div class="d-flex justify-space-between align-center w-100">
                <span class="body-2 font-weight-medium">Applicant Details: {{ selectedLoan.customerName }}</span>
                <span class="caption ml-4">ID: #{{ selectedLoan.loanReferenceNumber || '0001' }}</span>
              </div>
              <v-divider class="my-2"></v-divider>
              <div class="mt-2">
                <span class="mr-4">
                  <span class="caption grey--text">Credit Score</span>
                  <span class="body-2 font-weight-medium d-block">{{ selectedLoan.creditScore.toFixed(2) }}</span>
                </span>
                <span class="mr-4">
                  <span class="caption grey--text">Total Score</span> 
                  <span class="body-2 font-weight-medium d-block">{{ selectedLoan.totalScore.toFixed(2) }}</span>
                </span>
                <span>
                  <span class="caption grey--text">Max Possible</span>
                  <span class="body-2 font-weight-medium d-block">{{ selectedLoan.maxPossibleScore.toFixed(2) }}</span>
                </span>
              </div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="detailHeaders"
                :items="selectedLoan.scoreDetails"
                hide-actions
                class="elevation-1"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.riskFactorName }}</td>
                  <td>{{ props.item.riskFactorValue }}</td>
                  <td class="text-xs-right">{{ props.item.riskFactorScore }}</td>
                  <td class="text-xs-right">
                    {{ (props.item.subFactorScore * 100).toFixed(2) }}%
                    <v-tooltip v-if="props.item.usedDefaultScore" bottom>
                      <v-icon small slot="activator">info</v-icon>
                      <span>Default score used</span>
                    </v-tooltip>
                  </td>
                  <td class="text-xs-right">{{ props.item.finalScore.toFixed(2) }}</td>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions style="background-color: #f5f5f5;">
              <v-spacer></v-spacer>
              <v-btn flat @click="dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Overwrite Dialog -->
        <v-dialog v-model="overwriteDialog" max-width="500px">
          <v-card>
            <v-card-title class="py-3 px-4" style="background-color: #f5f5f5;">
              <span class="body-2 font-weight-medium">Existing Report Found</span>
            </v-card-title>
            <v-card-text class="pt-4">
              A report already exists for this month and year. Would you like to delete the existing report and create a new one?
            </v-card-text>
            <v-card-actions style="background-color: #f5f5f5;">
              <v-spacer></v-spacer>
              <v-btn flat @click="overwriteDialog = false">Cancel</v-btn>
              <v-btn flat @click="overwriteExistingReport">Yes, Overwrite</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>

    <!-- Snackbar for notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <v-btn flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>

    <error-dialog
      v-model="errorDialog.show"
      :title="errorDialog.title"
      :message="errorDialog.message"
      :details="errorDialog.details"
      :type="errorDialog.type"
      :suggestion="errorDialog.suggestion"
      :show-retry-button="errorDialog.showRetryButton"
      :show-action-button="errorDialog.showActionButton"
      :action-button-text="errorDialog.actionButtonText"
      :action-button-color="errorDialog.actionButtonColor"
      @retry="handleErrorRetry"
      @action="handleErrorAction"
    />
  </v-container>
</template>

<script>
import creditScoringService from '@/services/creditScoringService';
import CreditScoringFeaturesMenu from '@/components/CreditScoringFeaturesMenu.vue';
import ErrorDialog from '@/components/ErrorDialogComponent.vue';
import moment from 'moment';
import accessControlMixin from '@/mixins/accessControlMixin';

export default {
  name: 'CreditScoringReport',
  
  mixins: [accessControlMixin],
  
  components: {
    CreditScoringFeaturesMenu,
    ErrorDialog
  },

  data() {
    return {
      loading: false,
      exporting: false,
      dataLoading: false, 
      uploads: [],
      selectedUpload: null,
      summary: null,
      reportId: null,
      loans: [],
      dialog: false,
      overwriteDialog: false,
      existingReportId: null,
      selectedLoan: null,
      search: '',
      snackbar: {
        show: false,
        text: '',
        color: 'info'
      },

      fileDialogOpen: false,
      fileSearch: '',
      scoreFilter: null, 
      filterYear: null,
      tempSelectedUpload: null,
      
      fileHeaders: [
        { text: 'File Name', value: 'file_name', sortable: true },
        { text: 'Period', value: 'month', sortable: true },
        { text: 'Upload Date', value: 'created_at', sortable: true }
      ],

      headers: [
        { text: 'Customer Name', value: 'customerName' },
        { text: 'Loan Reference', value: 'loanReferenceNumber' },
        { text: 'Original Amount', value: 'originalLoanAmount', align: 'right' },
        { text: 'Current Balance', value: 'currentLoanBalance', align: 'right' },
        { text: 'ECL', value: 'ecl', align: 'right' },
        { text: 'Interest Accrued', value: 'interestAccrued', align: 'right' },
        { text: 'Stage', value: 'stage' },
        { text: 'Origination Date', value: 'loanOriginationDate' },
        { text: 'Maturity Date', value: 'loanMaturityDate' },
        { text: 'Credit Score', value: 'creditScore', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],

      detailHeaders: [
        { text: 'Risk Factor', value: 'riskFactorName' },
        { text: 'Value', value: 'riskFactorValue' },
        { text: 'Factor Score', value: 'riskFactorScore', align: 'right' },
        { text: 'Sub-Factor Score', value: 'subFactorScore', align: 'right' },
        { text: 'Final Score', value: 'finalScore', align: 'right' }
      ],

      scoreRanges: [],
      errorDialog: {
        show: false,
        title: 'Error',
        message: '',
        details: '',
        type: 'error',
        suggestion: '',
        showRetryButton: false,
        showActionButton: false,
        actionButtonText: 'Action',
        actionButtonColor: 'primary',
        retryCallback: null,
        actionCallback: null
      },
    }
  },

  computed: {
    filteredLoans() {
      if (!this.loans.length) return []

      let filtered = [...this.loans]

      if (this.scoreFilter) {
        // Get the specific filter key that was selected
        const selectedFilter = this.scoreFilter;
        
        // Filter loans based on the riskRating combination matching the selected filter
        filtered = filtered.filter(loan => {
          // Check if the loan has the required properties
          if (loan.riskRating && loan.riskRating.categoryName && loan.riskRating.definition) {
            // The key in scoreDistribution is in the format "categoryName: definition"
            // e.g. "Secured Loans: Very Poor"
            const riskKey = `${loan.riskRating.categoryName}: ${loan.riskRating.definition}`;
            return riskKey === selectedFilter;
          }
          return false;
        });
      }

      return filtered
    },

    filteredUploads() {
      if (!this.uploads.length) return []
      
      let filtered = [...this.uploads]
      
      // Apply year filter if set
      if (this.filterYear) {
        filtered = filtered.filter(upload => upload.year === this.filterYear)
      }
      
      return filtered
    },
    
    availableYears() {
      // Get unique years from uploads
      const years = [...new Set(this.uploads.map(upload => upload.year))]
        .filter(year => year) // Filter out null or undefined
        .sort((a, b) => b - a) // Sort descending (newest first)
      
      return years.map(year => ({
        text: String(year),
        value: year
      }))
    }
  },

  methods: {
    async initialize() {
      this.loading = true
      try {
        const response = await creditScoringService.fetchUploads()
        if (response.result) {
          this.uploads = response.value.map(upload => ({
            ...upload,
            displayName: `${upload.file_name} (${upload.month}/${upload.year})`
          }))
        } else {
          this.showErrorDialog({
            title: 'Data Loading Error',
            message: 'Failed to load uploads',
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error',
            showRetryButton: true,
            retryCallback: this.initialize
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Connection Error',
          message: 'Failed to connect to the server',
          details: error.message,
          type: 'error',
          showRetryButton: true,
          retryCallback: this.initialize
        });
        console.error('Error:', error)
      } finally {
        this.loading = false
      }
    },

    openFileSelector() {
      if (!this.hasPrivilege('Generate Reports')) {
        this.showUnauthorizedError('Generate Reports');
        return;
      }
      this.fileDialogOpen = true
      this.fileSearch = ''
      this.filterYear = null
      this.tempSelectedUpload = this.selectedUpload
    },
    
    selectFileFromList(file) {
      this.tempSelectedUpload = file._id
      this.$forceUpdate()
    },
    
    confirmFileSelection() {
      if (this.tempSelectedUpload) {
        this.selectedUpload = this.tempSelectedUpload
        this.fileDialogOpen = false;
        const selectedFile = this.uploads.find(upload => upload._id === this.selectedUpload)
        if (selectedFile) {
          this.showNotification(`Selected file: ${selectedFile.file_name}`, 'success')
        }
      }
    },
    
    clearSelectedFile() {
      if (!this.hasPrivilege('Generate Reports')) {
        this.showUnauthorizedError('Generate Reports');
        return;
      }
      this.selectedUpload = null;
      this.tempSelectedUpload = null;
      this.showNotification('File selection cleared', 'info');
    },
    
    getSelectedUploadName() {
      if (!this.selectedUpload) return ''
      
      const selectedFile = this.uploads.find(upload => upload._id === this.selectedUpload)
      if (!selectedFile) return ''
      
      return `${selectedFile.file_name} (${selectedFile.month}/${selectedFile.year})`
    },

    async handleUploadSelect() {
      if (!this.hasPrivilege('Generate Reports')) {
        this.showUnauthorizedError('Generate Reports');
        return;
      }
      if (!this.selectedUpload) {
        this.showErrorDialog({
          title: 'Input Required',
          message: 'Please select a file first',
          type: 'warning'
        });
        return;
      }

      this.loading = true;
      this.dataLoading = true;
      try {
        const response = await creditScoringService.calculateScores(this.selectedUpload)
        
        if (response.result) {
          this.processScoreResponse(response.value)
        } else {
          // Handle 409 error specifically
          if (response.status === 409 && response.data && response.data.existingReportId) {
            this.existingReportId = response.data.existingReportId
            this.overwriteDialog = true
          } else {
            const errorMessage = response.error ? response.error.message : 'Unknown error';
            const errorDetails = response.error && response.error.response && response.error.response.data ? 
                              JSON.stringify(response.error.response.data) : '';
            
            this.showErrorDialog({
              title: 'Report Generation Failed',
              message: 'Error calculating scores',
              details: errorDetails || errorMessage,
              type: 'error',
              showRetryButton: true,
              retryCallback: this.handleUploadSelect
            });
          }
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Report Generation Error',
          message: 'Failed to generate report',
          details: error.message,
          type: 'error',
          showRetryButton: true,
          retryCallback: this.handleUploadSelect
        });
        console.error('Error:', error)
      } finally {
        this.loading = false;
        this.dataLoading = false;
      }
    },

    processScoreResponse(data) {
      // Store the report ID
      this.reportId = data.reportId
      
      // Use the summary directly from the API
      this.summary = data.summary
      
      // Store the loans data
      this.loans = data.loans
      
      // Dynamically generate score ranges based on summary data
      this.generateScoreRanges();
      
      this.showNotification('Scores calculated and saved successfully', 'success')
    },
    
    generateScoreRanges() {
      // Clear existing ranges
      this.scoreRanges = []
      
      // Generate new ranges based on the score distribution in the summary
      if (this.summary && this.summary.scoreDistribution) {
        
        Object.keys(this.summary.scoreDistribution).forEach(key => {
          const count = this.summary.scoreDistribution[key];
          this.scoreRanges.push({
            text: `${key} (${count})`,
            value: key
          });
        });
        
        // Sort ranges by key for better organization
        this.scoreRanges.sort((a, b) => a.value.localeCompare(b.value));
      }
    },

    async overwriteExistingReport() {
      if (!this.hasPrivilege('Generate Reports')) {
        this.showUnauthorizedError('Generate Reports');
        return;
      }
      this.loading = true;
      this.dataLoading = true;
      this.overwriteDialog = false;
      
      try {
        // Delete the existing report
        const deleteResponse = await creditScoringService.deleteReport(this.existingReportId);
        
        if (!deleteResponse.result) {
          this.showErrorDialog({
            title: 'Delete Error',
            message: 'Failed to delete existing report',
            details: deleteResponse.error ? deleteResponse.error.message : 'Unknown error',
            type: 'error'
          });
          return;
        }
        
        // Calculate new scores
        const response = await creditScoringService.calculateScores(this.selectedUpload)
        
        if (response.result) {
          this.processScoreResponse(response.value)
          this.showNotification('Previous report deleted and new report created successfully', 'success')
        } else {
          this.showErrorDialog({
            title: 'Report Generation Error',
            message: 'Failed to generate new report',
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error',
            showRetryButton: true,
            retryCallback: () => this.handleUploadSelect()
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Overwrite Error',
          message: 'Failed to overwrite the existing report',
          details: error.message,
          type: 'error'
        });
        console.error('Error:', error)
      } finally {
        this.loading = false;
        this.dataLoading = false;
      }
    },

    async exportToExcel() {
      if (!this.hasPrivilege('Generate Reports')) {
        this.showUnauthorizedError('Generate Reports');
        return;
      }
      if (!this.reportId) {
        this.showErrorDialog({
          title: 'Export Error',
          message: 'No report available to export',
          type: 'warning'
        });
        return;
      }
      
      this.exporting = true;
      this.dataLoading = true;
      try {
        const response = await creditScoringService.exportReportToExcel(this.reportId)
        
        if (response.result) {
          // Create a download link for the blob
          const url = window.URL.createObjectURL(new Blob([response.value]))
          const link = document.createElement('a')
          link.href = url
          
          // Get month/year for filename
          const date = new Date()
          const month = date.getMonth() + 1
          const year = date.getFullYear()
          
          link.setAttribute('download', `credit-report-${month}-${year}.xlsx`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          
          this.showNotification('Report exported successfully', 'success')
        } else {
          this.showErrorDialog({
            title: 'Export Failed',
            message: 'Failed to export report',
            details: response.error ? response.error.message : 'Unknown error',
            type: 'error',
            showRetryButton: true,
            retryCallback: this.exportToExcel
          });
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Export Error',
          message: 'An error occurred while exporting the report',
          details: error.message,
          type: 'error',
          showRetryButton: true,
          retryCallback: this.exportToExcel
        });
        console.error('Export error:', error)
      } finally {
        this.exporting = false;
        this.dataLoading = false;
      }
    },

    showUnauthorizedError(privilegeName) {
      this.showErrorDialog({
        title: 'Access Denied',
        message: `You don't have the required "${privilegeName}" privilege`,
        details: 'This action requires specific permissions that are not assigned to your account.',
        type: 'error',
        suggestion: 'Please contact your administrator if you need access to this feature.'
      });
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    showDetails(loan) {
      this.selectedLoan = loan
      this.dialog = true
    },

    showNotification(text, color = 'info') {
      this.snackbar = {
        show: true,
        text,
        color
      }
    },

    showErrorDialog(options) {
      // Default values for options
      const errorTitle = options.title || 'Error';
      const errorMessage = options.message || 'An error occurred';
      const errorDetails = options.details || '';
      const errorType = options.type || 'error';
      const suggestion = options.suggestion || '';
      const showRetryButton = options.showRetryButton || false;
      
      // Make sure we have a valid action function if we're showing the action button
      const hasValidAction = typeof options.actionCallback === 'function';
      const showActionButton = options.showActionButton && hasValidAction;
      const actionButtonText = options.actionButtonText || 'Action';
      const actionButtonColor = options.actionButtonColor || 'primary';
      
      // Set error dialog properties
      this.errorDialog = {
        show: true,
        title: errorTitle,
        message: errorMessage,
        details: errorDetails,
        type: errorType,
        suggestion: suggestion,
        showRetryButton: showRetryButton,
        showActionButton: showActionButton,
        actionButtonText: actionButtonText,
        actionButtonColor: actionButtonColor,
        retryCallback: options.retryCallback || null,
        actionCallback: hasValidAction ? options.actionCallback : null
      };
      
      // Warn if action button is requested but no valid action provided
      if (options.showActionButton && !hasValidAction) {
        console.warn('showActionButton is true but no valid action handler was provided');
      }
    },

    handleErrorRetry() {
      if (typeof this.errorDialog.retryCallback === 'function') {
        this.errorDialog.retryCallback();
      }
    },

    handleErrorAction() {
      if (typeof this.errorDialog.actionCallback === 'function') {
        this.errorDialog.actionCallback();
      }
    },
  },

  created() {
    if (!this.hasPrivilege('Generate Reports')) {
      setTimeout(() => {
        this.showNotification('You are in view-only mode. The "Generate Reports" privilege is required to generate new reports.', 'info');
      }, 500);
    }
    this.initialize()
  },

  watch: {
    search() {
      if (this.$refs.dataTable && this.$refs.dataTable.pagination) {
        this.$refs.dataTable.pagination.page = 1
      }
    },
    scoreFilter() {
      if (this.$refs.dataTable && this.$refs.dataTable.pagination) {
        this.$refs.dataTable.pagination.page = 1
      }
    },
    filterYear() {
      // Reset to first page when filter changes, but safely check refs first
      if (this.$refs.fileTable && this.$refs.fileTable.pagination) {
        this.$refs.fileTable.pagination.page = 1
      }
    },
    fileSearch() {
      // Reset to first page when search changes, but safely check refs first
      if (this.$refs.fileTable && this.$refs.fileTable.pagination) {
        this.$refs.fileTable.pagination.page = 1
      }
    }
  }
}
</script>

<style scoped>
.v-chip {
  font-weight: medium;
}

.elevation-1 {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.v-datatable thead th {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #f5f5f5;
}

.title {
  font-weight: 500;
}

.selected-file {
  background-color: rgba(33, 150, 243, 0.15);
}

tr.selected-file td {
  font-weight: 500;
}
</style>